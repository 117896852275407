<template>
  <b-container class="hire-us">
    <div class="hire-us-container">
      <div>
        <div
          v-if="showBackButton"
          class="button-container"
        >
          <b-link
            class="py-0"
            variant="link"
            :hidden="backButtonHidden"
            @click="backButtonClicked"
          >
            <fa-icon icon="chevron-left" />
            Back
          </b-link>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-center p-1">
          <div class="hire-us-details">
            <div v-if="company && companyId">
              <div>
                <h4 class="hire-us-company-name">
                  {{ company.name }}
                </h4>
                <p v-if="jurisdictionDisplayed" class="home-state">
                  {{ jurisdictionLabelDisplayed }}
                  <span class="home-state-jurisdiction">
                    {{ jurisdictionDisplayed }}
                  </span>
                </p>
              </div>
            </div>
            <router-view @toggleButtonVisibility="toggleButtonVisibility" />
          </div>

          <div
            v-if="$router.currentRoute.meta.showSummary"
            class="hire-us-details mt-2"
          >
            <cart
              :processing-checkout="processingCheckout"
              :button-text="isCheckout"
              :show-promo-code="isCheckout === 'Checkout'"
            />
          </div>
        </div>
      </div>
    </div>
    <formation-info-modal
      ref="formation-info-modal"
      v-model="companyAdded"
      :company="company"
      :load-products="false"
      @formation-info-modal-complete="formationInfoModalCompleteHandler"
    />
    <company-domestic-registration-modal
      ref="company-domestic-registration-modal"
      :company="company"
      :load-products="false"
      @domestic-registration-changed="domesticRegistrationChanged"
    />
  </b-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import Cart from '@/components/HireUs/Cart'
  import FormationInfoModal from "@/components/StagelineV2/modals/FormationInfoModal"
  import CompanyDomesticRegistrationModal from "@/components/CompanyDomesticRegistrationModal"
  import {
    createOrFindClientInteractionLog,
    logInteractionByTypeAndName,
  } from '@/common/modules/clientInteractionLog'

  export default {
    name: 'HireUs',
    components: {
      Cart,
      CompanyDomesticRegistrationModal,
      FormationInfoModal,
    },
    props: {
      companyId: String,
      productKind: String,
      productId: String,
    },
    data() {
      return {
        productName: null,
        backButtonHidden: false,
        addContactButtonHidden: false,
        processingCheckout: false,
        loading: false,
        companyAdded: false,
        jurisdictions: [],
        hireUsInteraction: null,
        adminInvoiceOnly: false,
      }
    },
    computed: {
      ...mapGetters('companies', [
        'currentCompany',
        'domesticRegistration',
        'checkoutDomesticJurisdiction',
        'noJurisdictionForCompany',
        'formedElsewhere',
        'formationInfoObtained',
      ]),
      ...mapGetters('account', [
        'missingContactInformation',
      ]),
      ...mapGetters('checkout', [
        'findProduct',
        'allFormsValid',
        'cartItems',
        'cartEmpty',
        'findProduct',
        'productsLoaded',
      ]),
      ...mapGetters('products', [
        'optionalCategories',
      ]),
      company() {
        return this.currentCompany
      },
      isCheckout() {
        return this.$route.name === 'checkout-payment' ? 'Checkout' : 'Continue'
      },
      jurisdictionDisplayed() {
        return this?.domesticRegistration?.jurisdiction?.state_province_region
          || this?.checkoutDomesticJurisdiction?.state_province_region
      },
      jurisdictionLabelDisplayed() {
        if (this?.checkoutDomesticJurisdiction?.state_province_region) {
          return 'Intended Home State:'
        }
        return 'Home State: '
      },
      showBackButton() {
        return !!this.companyId && this.$route.name !== 'order-confirmation'
      },
      routeParams() {
        return this.$store.state.appRouter.params
      },
      routeCompanyId() {
        return this.routeParams?.companyId
      },
    },
    watch: {
      $route () {
        this.updateContext()
        if (this.company) {
          if (!this.formationInfoObtained && [null, undefined].includes(this.company?.config['formed_elsewhere'])) {
            this.presentFormationInfoModal()
            // Prevent competing modals
          } else if (this.noJurisdictionForCompany) {
            this.presentCompanyDomesticRegistrationModal()
          }

          if (this.routeCompanyId && !this.hireUsInteraction) this.setHireUsInteraction()
        }
      },
    },
    async mounted() {
      // Initialize client interaction tracking
      this.hireUsInteraction = null
      if (this.routeCompanyId) await this.setHireUsInteraction()
      this.$root.$on('log-interaction', ({ type, name, subName, additionalParams = null }) => {
        this.logHireUsInteraction(type, name, subName, additionalParams)
      })

      await this.updateContext()

      if (!this.jurisdictions.length) {
        await this.loadJurisdictions
      }

      this.$root.$on('cart-proceed-to-payment', () => {
        this.proceedToPayment()
      })
      this.$root.$on('cart-next-page', () => {
        this.nextPage()
      })
      this.$root.$on('checkout-without-payment', () => {
        this.adminInvoiceOnly = true
        this.nextPage()
      })
    },
    destroyed() {
      this.$root.$off('cart-proceed-to-payment')
      this.$root.$off('cart-next-page')
      this.$root.$off('log-interaction')
      this.$root.$off('checkout-without-payment')
    },
    methods: {
      ...mapActions('checkout', [
        'addToCart',
        'removeFromCart',
        'setupContext',
        'loadProducts',
        'loadCartItems',
        'checkout',
      ]),
      ...mapActions('companies', ['setCurrentCompany']),
      ...mapActions('jurisdictions', {
        loadJurisdictions: 'load',
      }),
      ...mapActions('products', [
        'getOptionalCategories',
      ]),
      async setHireUsInteraction() {
        if (this.hireUsInteraction === null || this.hireUsInteraction?.company_id != this.routeCompanyId) {
          this.hireUsInteraction = await createOrFindClientInteractionLog({
            category: 'hire-us',
            subCategory: 'hire-us',
            companyId: this.routeCompanyId,
            incompleteLogFromToday: true,
          })
        }
      },
      async backButtonClicked() {
        this.logHireUsInteraction('button-redirect','navigate-previous-page',  this.$router.currentRoute.name)
        await this.loadCartItems()
        await this.$router.go(-1)
      },
      async updateContext() {
        if (!this.companyId) return

        this.loading = true

        const context = {
          productKind: this.productKind,
          productId: this.productId,
        }

        await this.setCurrentCompany({ id: this.companyId, force: true })
        await this.setupContext(context)

        if (!this.productsLoaded) await this.loadProducts()

        this.loading = false
      },
      toggleButtonVisibility(values) {
        this.backButtonHidden = values.backButtonHidden
        this.addContactButtonHidden = values.addContactButtonHidden
      },
      showAddContactModal() {
        this.$refs['contact-modal'].show()
      },
      async nextPage() {
        const current = this.$router.currentRoute

        if (current.name !== 'checkout-payment') {
          this.logHireUsInteraction('button-redirect', 'navigate-next-page', this.$router.currentRoute.name)
        }

        //TODO: this is where we need to maybe deviate...but maybe not. just add in 'stageline' -- nah Change
        if (current.name === 'checkout-payment') {
          this.processingCheckout = true
          await this.checkout(this)
          this.hireUsInteraction = null
          this.processingCheckout = false
        } else if (current.name === 'product-details') {
          this.missingContactInformation ?
            this.$bvModal.show('collectAccountInfoModal') :
            await this.proceed(current)
        } else {
          await this.proceedToPayment()
        }
      },
      async loadOptionalCategories (categoryId) {
        if (categoryId && this.companyId) {
          const params = {
            companyId: this.companyId,
            categoryId: categoryId,
          }

          await this.getOptionalCategories(params)
        }
      },
      async proceed(current) {
        await this.loadOptionalCategories(current?.params.categoryId)
        if (this.optionalCategories.length) {
          await this.$router.push({ name: 'optional-items', params: current.params })
        } else {
          await this.proceedToPayment()
        }
      },
      async proceedToPayment(){
        await this.$router.push({
          // was stageline
          name: 'checkout-payment',
          params: this.$router.currentRoute.params,
          query: { ids: this.cartItems.filter(item => item.filing_method_id !== null).map(item => item.filing_method_id) },
        })
      },

      presentFormationInfoModal() {
        this.$refs['formation-info-modal'].show()
      },

      formationInfoModalCompleteHandler() {
        if (this.noJurisdictionForCompany) {
          this.presentCompanyDomesticRegistrationModal()
        }
      },

      presentCompanyDomesticRegistrationModal() {
        this.$refs['company-domestic-registration-modal'].show()
      },

      domesticRegistrationChanged() {
        // doesn't seem to be required in this case
      },

      logHireUsInteraction(type, name, subName = null, additionalParams = null) {
        if (!this.hireUsInteraction) return
        try {
          logInteractionByTypeAndName({
            id: this.hireUsInteraction.id,
            type: type,
            name: name,
            subName: subName,
            additionalParams: additionalParams,
          })
        } catch (ex) {
          // Just Suppressing the error
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $black: #231F20;

  // Uncomment these for turning into tile format
  //.multiple-product-list {
  //  display: flex;
  //  flex-direction: row;
  //  flex-flow: wrap;
  //  gap: 10px;
  //}
  //
  //.multiple-product-selection {
  //  &:hover {
  //    left: 0px !important;
  //    bottom: 5px;
  //  }
  //
  //  height: 300px !important;
  //  width: 300px !important;
  //
  //  .main {
  //    flex-flow: column wrap !important;
  //
  //    .name{
  //      justify-content: center !important;
  //    }
  //  }
  //}


  h4,
  p {
    color: $black;
  }

  h4 {
    font-size: 1.25em;
    font-weight: 600;
  }

  p {
    font-size: 1.0em;
    font-weight: 400;
  }

  .hire-us {

    .hire-us-container {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      .button-container {
        padding: 0.5em 0;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 48rem;
      }

      .hire-us-details {
        margin: 0.3125em;
        flex: 1 0 60%;
        max-width: 46.875rem;

        .hire-us-company-name {
          font-weight: 700;
        }

        .home-state {
          margin-top: 0;
          font-weight: 700;

          .home-state-jurisdiction {
            font-weight: 400;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .hire-us {

      .hire-us-details {

        h4.hire-us-company-name {
          font-size: 1.125em;
        }
      }
    }
  }

  @media print {
    .hire-us {
      width: 50rem;
      margin: 0;
      padding: 0;
    }
  }
</style>
